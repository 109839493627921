import React, {useEffect, useState} from "react";
import {Link, withRouter} from "react-router-dom";

function Header({location}) {
  const toggleHeaderMenu = (e) => {
    e.preventDefault();
    document.querySelector("body").classList.toggle("az-header-menu-show");
  }

  const isPathActive = (path) => {
    return location.pathname.startsWith(path);
  }

  useEffect(() => {
    document.querySelector("body").classList.remove("az-header-menu-show");
  }, [location]);

  const [isDesktop, setDesktop] = useState(window.innerWidth > 900);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 900);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <div>
      <div className="az-header">
        <div className="container">
          <div className="az-header-left">
            <a
              id="azMenuShow"
              onClick={event => toggleHeaderMenu(event)}
              className="az-header-menu-icon d-lg-none"
              href="#/"
            >
              <span></span>
            </a>
            <a href="/" className="az-logo d-block">
              <span>SLK</span>
            </a>
          </div>
          {location.pathname === '/' && isDesktop ? null : (
            <div className="az-header-menu">
              <div className="az-header-menu-header">
                <Link to="/" className="az-logo">
                  <span></span> slk
                </Link>
                <a
                  href="#/"
                  onClick={event => toggleHeaderMenu(event)}
                  className="close"
                >
                  &times;
                </a>
              </div>
              <ul className="nav">
                <li
                  className={
                    isPathActive("/our-way")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link to="/our-way" className="nav-link">
                    <span className="tx-10">Our &nbsp;</span> Way
                  </Link>
                </li>
                <li
                  className={
                    isPathActive("/our-progress")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link to="/our-progress" className="nav-link">
                    <span className="tx-10">Our &nbsp;</span> Progress
                  </Link>
                </li>
                <li
                  className={
                    isPathActive("/our-customers")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link to="/our-customers" className="nav-link">
                    <span className="tx-10">Our &nbsp;</span> Customers
                  </Link>
                </li>
                <li
                  className={
                    isPathActive("/our-people")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link to="/our-people" className="nav-link">
                    <span className="tx-10">Our &nbsp;</span> People
                  </Link>
                </li>
                <li
                  className={
                    isPathActive("/our-impact")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link to="/our-impact" className="nav-link">
                    <span className="tx-10">Our &nbsp;</span> Impact
                  </Link>
                </li>
                <li
                  className={
                    isPathActive("/our-success")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link to="/our-success" className="nav-link">
                    <span className="tx-10">Our &nbsp;</span> Success
                  </Link>
                </li>
                <li
                  className={
                    isPathActive("/our-learnings")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link to="/our-learnings" className="nav-link">
                    <span className="tx-10">Our &nbsp;</span> Learnings
                  </Link>
                </li>
                <li
                  className={
                    isPathActive("/our-direction")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link to="/our-direction" className="nav-link">
                    <span className="tx-10">Our &nbsp;</span> Direction
                  </Link>
                </li>
              </ul>
            </div>
          )}
          <div className="az-header-right">
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(Header);
