import React, {Component, lazy, Suspense} from 'react'
import {Route, Switch} from 'react-router-dom'

const OurWay = lazy(() => import('./ourway/OurWay'))
const OurProgress = lazy(() => import('./ourprogress/OurProgress'))
const OurCustomers = lazy(() => import('./ourcustomers/OurCustomers'))
const OurPeople = lazy(() => import('./ourpeople/OurPeople'))
const OurImpact = lazy(() => import('./ourimpact/OurImpact'))
const OurSuccess = lazy(() => import('./oursuccess/OurSuccess'))
const OurLearnings = lazy(() => import('./ourlearnings/OurLearnings'))
const OurDirection = lazy(() => import('./ourdirection/OurDirection'))
const Home = lazy(() => import('./home/Home'))

export class AppRoutes extends Component {
  render() {
    return (
      <Suspense fallback=''>
        <Switch>
          <Route exact path="/" component={Home}/>
          <Route exact path="/our-way" component={OurWay}/>
          <Route exact path="/our-progress" component={OurProgress}/>
          <Route exact path="/our-customers" component={OurCustomers}/>
          <Route exact path="/our-people" component={OurPeople}/>
          <Route exact path="/our-impact" component={OurImpact}/>
          <Route exact path="/our-success" component={OurSuccess}/>
          <Route exact path="/our-learnings" component={OurLearnings}/>
          <Route exact path="/our-direction" component={OurDirection}/>
        </Switch>
      </Suspense>
    )
  }
}

export default AppRoutes
